import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Stack } from '@mui/material';
import { useBooleanState } from '@fingo/lib/hooks';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import StopCircleIcon from '@mui/icons-material/StopCircle';

const WhatsappAudioPlayer = ({ file }) => {
  const [isPlaying, setIsPlaying] = useBooleanState();
  const [progress, setProgress] = useState(0);
  const audioElem = useRef();

  useEffect(() => {
    if (isPlaying) {
      audioElem.current.play();
    } else {
      audioElem.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (progress === 100) {
      setIsPlaying(false);
    }
  }, [progress]);

  const onPlaying = () => {
    const { duration } = audioElem.current;
    const ct = audioElem.current.currentTime;
    setProgress((ct / duration) * 100);
  };

  return (
    <Stack sx={{ width: '300px' }} direction="row" alignItems="center" spacing={0.8}>
      {isPlaying ? <StopCircleIcon onClick={() => setIsPlaying(false)} />
        : <PlayCircleFilledWhiteIcon onClick={() => setIsPlaying(true)} />}
      <LinearProgress variant="determinate" value={progress} sx={{ width: '270px' }} />
      <audio src={file.file.url} ref={audioElem} onTimeUpdate={onPlaying}>
        <track kind="captions" />
      </audio>
    </Stack>
  );
};

WhatsappAudioPlayer.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default WhatsappAudioPlayer;
