import React from 'react';
import { CompanyProfileCell } from '@fingo/lib/components/cells';
import { Grid, Typography } from '@mui/material';
import { snakeToCamelWithSpaces } from '@fingo/lib/helpers';
import { DeleteControlGroupButton } from '@fingo/lib/components/buttons';
import { MailTemplateButton } from '../components/contactability-manager';

export const VIEW_TITLE = 'Contactabilidad Empresas';

export const TITLE_INFORMATION_TOOLTIP = `En este panel puedes ver y editar qué mails se mandan
a las empresas con credenciales válidas.`;

export const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100];

export const INITIAL_PAGE_SIZE = 25;

export const HEADERS = [
  'name',
  'keyContact',
  'preofferEmail',
  'whatsappEmail',
];

export const COLUMNS = [
  {
    field: 'name',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row} />,
    sortable: true,
    flex: 1,
  },
  {
    field: 'keyContact',
    headerName: 'Contacto Clave',
    flex: 1,
    renderCell: (params) => (
      <Grid container justifyContent="flex-start" direction="column">
        <Typography variant="body1">{params.row.contacts?.edges?.[0]?.node?.name || ''}</Typography>
        <Typography variant="body2">{params.row.contacts?.edges?.[0]?.node?.email || ''}</Typography>
      </Grid>
    ),
  },
];

export const HEADERS_DETAIL = [
  'mailParameter_Mail_EmailName',
  'mailParameter_Country_Name',
  'mailParameter_RequestingPlatform_Code',
  'mailParameter_MailTemplate_Version',
  'delete',
];

export const COLUMNS_DETAIL = [
  {
    field: 'mailParameter_Mail_EmailName',
    headerName: 'Nombre del correo',
    sortable: true,
    flex: 1,
    renderCell: ({ row }) => (
      <Typography>
        {snakeToCamelWithSpaces(
          row.mailParameter.mail?.emailName,
        )}
      </Typography>
    ),
  },
  {
    field: 'mailParameter_Country_Name',
    headerName: 'País',
    sortable: true,
    flex: 0.5,
    renderCell: ({ row }) => (
      <Typography>
        {row.mailParameter.country?.name}
      </Typography>
    ),
  },
  {
    field: 'mailParameter_RequestingPlatform_Code',
    headerName: 'Plataforma',
    sortable: true,
    flex: 0.5,
    renderCell: ({ row }) => (
      <Typography>
        {row.mailParameter.requestingPlatform?.code}
      </Typography>
    ),
  },
  {
    field: 'mailParameter_MailTemplate_Version',
    headerName: 'Template',
    flex: 0.5,
    renderCell: ({ row }) => (
      <MailTemplateButton
        mailParam={row.mailParameter}
        companyId={row.companyId}
        masterEntityName={row.masterEntityName}
      />
    ),
    sortable: true,
  },
  {
    field: 'delete',
    flex: 0.5,
    renderCell: ({ row }) => (
      <DeleteControlGroupButton
        companyId={row.companyId}
        mailParameterId={row.mailParameter.id}
      />
    ),
  },
];
