import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import CameraAlt from '@mui/icons-material/CameraAlt';
import Mic from '@mui/icons-material/Mic';
import FileCopy from '@mui/icons-material/FileCopy';
import VideoCameraBack from '@mui/icons-material/VideoCameraBack';

const MediaBodyIcon = ({ contentType }) => {
  const MediaBodyIconType = {
    'image/bmp': { Icon: CameraAlt, Label: 'Foto' },
    'image/tiff': { Icon: CameraAlt, Label: 'Foto' },
    'image/jpeg': { Icon: CameraAlt, Label: 'Foto' },
    'image/jpg': { Icon: CameraAlt, Label: 'Foto' },
    'image/gif': { Icon: CameraAlt, Label: 'Foto' },
    'image/png': { Icon: CameraAlt, Label: 'Foto' },
    'audio/basic': { Icon: Mic, Label: 'Audio' },
    'audio/L24': { Icon: Mic, Label: 'Audio' },
    'audio/mp4': { Icon: Mic, Label: 'Audio' },
    'audio/mpeg': { Icon: Mic, Label: 'Audio' },
    'audio/ogg': { Icon: Mic, Label: 'Audio' },
    'audio/vnd.rn-realaudio': { Icon: Mic, Label: 'Audio' },
    'audio/vnd.wave': { Icon: Mic, Label: 'Audio' },
    'audio/3gpp': { Icon: Mic, Label: 'Audio' },
    'audio/3gpp2': { Icon: Mic, Label: 'Audio' },
    'audio/ac3': { Icon: Mic, Label: 'Audio' },
    'audio/webm': { Icon: Mic, Label: 'Audio' },
    'audio/amr-nb': { Icon: Mic, Label: 'Audio' },
    'audio/amr': { Icon: Mic, Label: 'Audio' },
    'video/mpeg': { Icon: VideoCameraBack, Label: 'Video' },
    'video/mp4': { Icon: VideoCameraBack, Label: 'Video' },
    'video/quicktime': { Icon: VideoCameraBack, Label: 'Video' },
    'video/webm': { Icon: VideoCameraBack, Label: 'Video' },
    'video/3gpp': { Icon: VideoCameraBack, Label: 'Video' },
    'video/3gpp2': { Icon: VideoCameraBack, Label: 'Video' },
    'video/3gpp-tt': { Icon: VideoCameraBack, Label: 'Video' },
    'video/H261': { Icon: VideoCameraBack, Label: 'Video' },
    'video/H263': { Icon: VideoCameraBack, Label: 'Video' },
    'video/H263-1998': { Icon: VideoCameraBack, Label: 'Video' },
    'video/H263-2000': { Icon: VideoCameraBack, Label: 'Video' },
    'video/H264': { Icon: VideoCameraBack, Label: 'Video' },
    'application/pdf': { Icon: FileCopy, Label: 'Archivo' },
    'application/vcard': { Icon: FileCopy, Label: 'Archivo' },
    'application/vnd.apple.pkpass': { Icon: FileCopy, Label: 'Archivo' },
    'application/msword': { Icon: FileCopy, Label: 'Archivo' },
    'application/vnd.ms-excel': { Icon: FileCopy, Label: 'Archivo' },
    'application/vnd.ms-powerpoint': { Icon: FileCopy, Label: 'Archivo' },
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': { Icon: FileCopy, Label: 'Archivo' },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { Icon: FileCopy, Label: 'Archivo' },
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { Icon: FileCopy, Label: 'Archivo' },
  };
  const { Icon, Label } = MediaBodyIconType[contentType];
  return (
    <Stack direction="row" alignContent="center">
      <Icon sx={{ height: '15px', alignSelf: 'center' }} />
      <Typography>
        {Label}
      </Typography>
    </Stack>
  );
};

MediaBodyIcon.propTypes = {
  contentType: PropTypes.string.isRequired,
};

export default MediaBodyIcon;
