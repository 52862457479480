import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { MASTER_ENTITY_WHITELISTS } from '@fingo/lib/graphql/customers';
import { whitelistDefaultColumns } from '@fingo/lib/constants';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';

const WhitelistDetails = ({ masterEntityId, filter }) => {
  const filterToActive = {
    all: null,
    active: true,
    inactive: false,
  }[filter];
  return (
    <Stack sx={{ height: 400 }}>
      <FingoMainView
        id="master-entity-whitelist-details"
        query={MASTER_ENTITY_WHITELISTS}
        queryCustomVariables={{
          masterEntityId,
          active: filterToActive,
        }}
        slots={{
          table: FingoDataGrid,
        }}
        slotProps={{
          table: {
            columns: whitelistDefaultColumns,
            noRowsMessage: () => <Typography>El cliente no tiene whitelist</Typography>,
            initialOrderBy: 'active',
            includeHeaders: [
              'active',
              'emitter',
              'receiver',
              'monthlyRate',
              'defaultRate',
              'retentionRate',
              'switchComponent',
              'editComponent',
            ],
            mobileHeaders: [
              'emitter',
              'receiver',
              'active',
            ],
            rowsPerPageOptions: [100],
          },
        }}
        onCompletedSetRows={(data) => {
          const { rateWhitelistEmitter, rateWhitelistReceiver } = data.getMasterEntity;
          const emitterWhitelist = rateWhitelistEmitter.edges.map((row) => row.node);
          const receiverWhitelist = rateWhitelistReceiver.edges.map((row) => row.node);
          return emitterWhitelist.concat(receiverWhitelist);
        }}
        onCompletedSetLength={(data) => {
          const { rateWhitelistEmitter, rateWhitelistReceiver } = data.getMasterEntity;
          return rateWhitelistEmitter.totalCount + rateWhitelistReceiver.totalCount;
        }}
      />
    </Stack>
  );
};

WhitelistDetails.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
  filter: PropTypes.oneOf(['all', 'active', 'inactive']).isRequired,
};

export default WhitelistDetails;
