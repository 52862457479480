/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Status } from '@fingo/lib/components/dataGridCells';
import { formatRut } from '@fingo/lib/helpers';
import { FolioWithProfileAndAlerts, ComercialOperationAlertArray } from '@fingo/lib/components/cells';
import { Grid, Typography } from '@mui/material';
import TransferInvoiceRequirements from '../../treasury/components/transfers/TransferInvoiceRequirements';

export const OPERATION_MANAGER_COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
    headerAlign: 'left',
    minWidth: 80,
    renderCell: ({ row }) => (
      <FolioWithProfileAndAlerts invoice={row} AlertsArray={ComercialOperationAlertArray} />
    ),
  },
  {
    field: 'requirements',
    headerName: 'Requerimientos para el giro',
    sortable: false,
    filterable: false,
    width: 180,
    renderCell: ({ row }) => <TransferInvoiceRequirements invoice={row} disabled />,
  },
  {
    field: 'company_MasterEntity_Name',
    headerName: 'Emisor',
    type: 'string',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => (
      <Grid width="100%" alignItems="flex-start" justifyContent="center">
        <Typography variant="body2" width="inherit" noWrap>{params.row.company.masterEntity.name}</Typography>
        <Typography variant="caption" width="inherit" noWrap>{formatRut(params.row.company.rut)}</Typography>
      </Grid>
    ),
  },
  {
    field: 'cessionStatus',
    headerName: 'Estado',
    headerAlign: 'left',
    sortable: false,
    filterable: false,
    width: 150,
    align: 'center',
    renderCell: ({ row }) => (
      <Status status={row.status} paymentDate={row.paymentDate} oneStepper />
    ),
  },
];
