import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const WhatsappImageMessage = ({ file }) => (
  <Box
    name={file.file.name}
    url={file.file.url}
    onClick={() => window.open(file.file.url, '_blank')}
    src={file.file.url}
    component="img"
    sx={{
      width: { xs: '50px', md: '300px' },
    }}
  />
);

WhatsappImageMessage.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default WhatsappImageMessage;
