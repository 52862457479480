import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import MessageStatus from '../Messages/MessageStatus';
import MediaBodyIcon from './MediaBodyIcon';

const MediaBodyItem = ({ conversation }) => {
  const { lastMessage } = conversation;
  const { whatsapplogfileSet } = lastMessage;
  return (
    <Stack direction="row" alignItems="center">
      {!lastMessage.inbox ? <MessageStatus lastEvent={lastMessage.lastEvent} sx={{ height: '15px' }} /> : null}
      {!whatsapplogfileSet.length ? <Typography noWrap>{lastMessage.body}</Typography>
        : <MediaBodyIcon contentType={whatsapplogfileSet[0].contentType} />}
    </Stack>
  );
};

MediaBodyItem.propTypes = {
  conversation: PropTypes.shape({
    lastMessage: PropTypes.shape({
      id: PropTypes.string.isRequired,
      inbox: PropTypes.bool.isRequired,
      body: PropTypes.string.isRequired,
      lastEvent: PropTypes.shape({
        id: PropTypes.string,
        whatsappLogEvent: PropTypes.shape({
          id: PropTypes.string,
          status: PropTypes.string,
        }),
      }).isRequired,
      whatsapplogfileSet: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        contentType: PropTypes.string,
      })),
    }),
  }).isRequired,
};

export default MediaBodyItem;
