import React, { useState, useEffect } from 'react';
import useQueryParams from '@fingo/lib/hooks/useQueryParams';
import { useQuery, useMutation } from '@apollo/client';
import { WHATSAPP_CONVERSATIONS, UPDATE_WHATSAPP_LAST_MESSAGE_SEEN } from '@fingo/lib/graphql';
import { Stack, Typography, Box } from '@mui/material';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import SidebarView from './Sidebar/SidebarView';
import ChatView from './ChatView/ChatView';

const ConversationsView = () => {
  const country = useGetCountryFromUrl();
  const phoneNumbers = {
    Chile: '+56',
    Mexico: '+52',
  }[country.name];
  const queryParams = useQueryParams();
  const twilioSenderId = queryParams.get('twilio_sender_id');
  const conversationHashId = queryParams.get('conversation_hash_id');
  const [page, setPage] = useState(0);
  const [selectedConversation, setSelectedConversation] = useState(
    { hashId: conversationHashId, twilioId: parseInt(twilioSenderId, 10) || null },
  );
  const [filters, setFilters] = useState(
    { globalFilter: '', executiveFilter: null, marketingFilter: null },
  );
  const { data, loading } = useQuery(WHATSAPP_CONVERSATIONS, {
    variables: {
      first: 15,
      offset: page * 15,
      phoneNumbers,
      orderBy: '-lastMessageDatetime',
      globalFilter: filters.globalFilter,
      ...(filters.executiveFilter?.id && {
        relatedUser_Companies_ExecutiveAssignedId: filters.executiveFilter?.id }
      ),
      ...((filters.executiveFilter?.id === null) && {
        relatedUser_Companies_ExecutiveAssignedId_Isnull: true }
      ),
      ...((filters.marketingFilter?.id) && {
        relatedUser_Contacts_Marketingcampaigncontact_MarketingCampaign_MailId:
        filters.marketingFilter?.id,
      }
      ),
      ...((filters.marketingFilter?.id === null) && {
        relatedUser_Contacts_Marketingcampaigncontact_MarketingCampaign_MailId_Isnull: true }
      ),
    },
  });

  const [updateLastMessageSeen] = useMutation(
    UPDATE_WHATSAPP_LAST_MESSAGE_SEEN,
    {
      variables: {
        hashId: selectedConversation.hashId,
        twilioId: selectedConversation.twilioId,
      },
    },
  );

  useEffect(() => {
    if (selectedConversation.hashId && selectedConversation.twilioId) {
      updateLastMessageSeen();
    }
  }, [selectedConversation]);

  const conversations = data?.whatsappConversations?.edges ?? [];

  return (
    <Stack direction="row" spacing={1.3} sx={{ height: '100%' }}>
      <Box sx={{ backgroundColor: 'gray.light', width: '25%', borderRadius: '15px' }}>
        <SidebarView
          page={page}
          setPage={setPage}
          conversations={conversations}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          filters={filters}
          setFilters={setFilters}
          loading={loading}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: 'gray.light',
          width: '75%',
          borderRadius: '15px',
          textAlign: 'center',
          alignContent: 'center',
        }}
      >
        {!selectedConversation.hashId ? <Typography>Selecciona un chat</Typography> : (
          <ChatView
            hashId={selectedConversation.hashId}
            twilioId={selectedConversation.twilioId}
          />
        )}
      </Box>
    </Stack>
  );
};

export default ConversationsView;
