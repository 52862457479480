import React from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';

const LoadingSidebar = ({ numberRows }) => (
  <Box
    sx={{
      height: '100%',
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column-reverse',
      borderRadius: 0,
    }}
    p={1}
  >
    {new Array(numberRows).fill(null).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Skeleton key={index} height={60} />
    ))}
  </Box>
);

LoadingSidebar.propTypes = {
  numberRows: PropTypes.number.isRequired,
};

export default LoadingSidebar;
